.mobile {display: none;}
.site {overflow: hidden;}
.homeSlider { position: relative; height: 100vh;position: relative;float: left;width: 100%;margin-bottom: 0 !important;
  &__Slide {background-size: cover;background-repeat: no-repeat; position: relative;z-index: 1;height: 100vh !important;float: left;width: 100%;
    &:after {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0; background: alpha($black,0.3); z-index: -1;}
  }
  &__Content { height: 100vh; position: relative; float: left; width: 100%;}
  &__Box { float: left; width: 100%;padding-left: 20%;padding-right: 20%; position: relative;  top: 50%; transform:translateY(-50%);}
  &__Heading { text-align: left; font-size: 100px; line-height: 112px; margin: 0; padding: 0; font-weight: 500; color: $white; position: relative;
    span {color: $brand;}
  }
  &__Cnt { text-align: left; font-size: 20px; line-height: 32px; margin: 0; padding: 0; font-weight: 300; color: $white; position: relative;}
  &__More {position: relative;@include moreButton($brand, $brand); color: $white;margin-top: 50px;padding: 10px 20px;
    &:hover {
      color: $brand; border-color: $white;
    }
  }
  .slick-dots { bottom: 0; 
    li {
      button {
        &:before { font-size: 18px; color: $white; opacity: 0.5; }
      }
    }
    li.slick-active {
      button {
        &:before { color: $white; opacity: 1; }
      }
    }
  }
}
.about {
  float: left;width: 100%;
  &__Left {float: left;width: 50%;height: 600px;background: $brand2;padding: 40px;
    h2 {font-size: 30px;color: $brand;margin-top: 0;}
    p {font-weight: lighter;color: $brand;}
    a {@include moreButton($brand, transparent);margin-top: 50px;
      &:before {background: $brand;}
      &:hover {color: $white;}
    }
  }
  &__Right {float: left;width: 50%;height: 600px;
    &--image {float: left;width: 100%;height: 600px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
  }
}
.aboutBottom {
  float: left;width: 100%;
  &__Right {float: left;width: 50%;height: 600px;background: $brand2;padding: 40px;
    h2 {font-size: 30px;color: $brand;margin-top: 0;}
    p {font-weight: lighter;color: $brand;}
    a {@include moreButton($brand, transparent);margin-top: 50px;
      &:before {background: $brand;}
      &:hover {color: $white;}
    }
  }
  &__Left {float: left;width: 50%;height: 600px;
    &--image {float: left;width: 100%;height: 600px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
  }
}
.homeBottom {float: left;width: 100%;height: 800px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;
  &__Box {float: left;width: 600px;height: auto;background: $brand2;padding: 40px;position: absolute;top: 100px;left: 25%;
    h2 {font-size: 30px;color: $brand;margin-top: 0}
    p {font-weight: lighter;color: $brand;}
    a {@include moreButton($brand, transparent);margin-top: 50px;
      &:before {background: $brand;}
      &:hover {color: $white;}
    }
  }

}