// COLORS
  $brand: #084480;
  $brand2: #f4f0e5;
  $red:#e30613;
  $white: #FFF;
  $gray: #464952; 
  $black: #000;
  $gray-light: #999;
  
  $social: #6d6b64;
  $facebook: #3B5997;
  $twitter: #4099FF;
  
  // TYPE
 @import url(https://fonts.googleapis.com/css?family=Poppins:400,300,700&subset=latin,latin-ext);
  @import url(https://fonts.googleapis.com/css?family=Allura&subset=latin,latin-ext);
  

  $font-size: 16px;
  $line-height: 1.5;
  $default: 'Poppins', sans-serif;
  // $default: 'Source Sans Pro', sans-serif;
  $title: 'Allura', cursive;

  // $light: 100;
  $regular: 400;
  $mediumbold: 500;
  $semibold: 600;
  $bold: 700;
  $ultra: 900;

  $xxsmall: em(10px);
  $xsmall: em(12px);
  $small: em(14px);
  $medium: em(16px);
  $large: em(18px);
  $xlarge: em(20px);
  $xxlarge: em(24px);
  
// DEFAULTS
  body { font: #{$font-size}/#{$line-height} $default; }


  @function alpha($color: $black, $opacity: 0.5) { 
    $alpha-color-value: rgba($color, $opacity); 
    @return $alpha-color-value;
  }