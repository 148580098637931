// Alpha color
@function alpha-color($color: $black, $opacity: 0.5) { 
  $alpha-color-value: rgba($color, $opacity); 
  @return $alpha-color-value;
}

// Align to center
@mixin center($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

// Target/context formula
@function percent($target, $context: $max-width) {
  $target-context-num: $target/$context * 100%;
  @return $target-context-num;
}

// EM formula
@function em($target, $context: $font-size) {
  $target-context-num: $target/$context * 1em;
  @return $target-context-num;
}

// Elements
@mixin inlineNav($ul: true) {
  @if $ul == true {
    ul { margin: 0; padding: 0; }
  }
  li { display: inline-block; vertical-align: top; }
}

// Scollbars
@mixin scrollbars($size, $foreground-color, $background-color) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }
}

// More button
  @mixin moreButton($fgcolor: $white, $bgcolor: transparent) { border: 2px $fgcolor solid; display: inline-block; padding: 0 70px; line-height: 46px; text-decoration: none; letter-spacing: 2.5px; font-size: 14px; text-transform: uppercase; color: $fgcolor; position: relative; overflow: hidden; z-index: 1; vertical-align: bottom; background: $bgcolor; transition: 900ms $ease-out-quint;
    &:hover { color: $black;
      &:before { opacity: 1; right: -40px; left: -40px; }
    }
    &:before { content: ''; display: block; z-index: -1; position: absolute; top: 0; bottom: -10px; transform: skew(40deg); background: $white; left: 100%; right: 100%; transition: 900ms $ease-out-quint; }
  }
  
// Overlay
  @mixin overlay($opacity: 0.5, $color: $black, $element: before) { position: relative; z-index: 1;
    &:#{$element} { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: alpha-color($color, $opacity); z-index: -1; }
  }