// Dev specific
#__bs_notify__ { position: fixed; top: 50%; left: 50%; margin-left: -125px !important; width: 250px; height: 50px; border-radius: 0 !important; opacity: 0.5; }
// ESSENTIALS
.center { @include outer-container; }
.clearfix { @include clearfix; }
::selection { background: darken($white, 20%); text-shadow: none; }
// BLOCKS
.t-Title { text-transform: uppercase; font-size: em(36px); line-height: 44px; letter-spacing: 3.5px; margin-top: 100px; font-weight: 300; margin-bottom: 0; text-align: center; 
  &--with-MarginBottom { @extend .t-Title; margin-bottom: 70px; }
}
// OBJECTS

// Select form
.t-input-Select { background: $gray; box-sizing: border-box; position: relative; display: inline-block; vertical-align: middle; overflow: hidden; transition: 200ms; backface-visibility: hidden; margin-right: 10px;
  option { background: transparent; }
  &:hover { background: darken($gray, 5%);
    &:after { background-color: darken($gray, 5%); }
  }
  &:active { transform: scale(0.97); transition: 60ms; }
  &:after { display: block; content: ""; position: absolute; width: 40px; height: 100%; line-height: 43px; font-size: em(12px); color: darken($white, 40%); top: 0; right: 0; text-align: center; background: no-repeat center center; background-color: $gray; pointer-events: none; transition: 200ms; }
  select { border: 0; -webkit-appearance: none; text-indent: 0.01px; text-overflow: ''; padding: 9px 10px 8px; outline: none; cursor: pointer; color: darken($white, 70%); display: block; background: $white; background: transparent; padding-right: 40px; display: block; }
  select[disabled] { color: rgba(0, 0, 0, .3); }
}
// Buttons
.t-MoreButton { @include moreButton; }

// TEMPLATE
img { max-width: 100%; height: auto; }
a { cursor: pointer; }