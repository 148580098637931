.defaultSlider { position: relative; height: 600px;position: relative;margin-top: -100px;float: left;width: 100%;margin-bottom: 0 !important;
  &__Slide {background-size: cover;background-repeat: no-repeat;background-position: center center; position: relative;z-index: 1;
    &:after {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0; background: alpha($black,0.6); z-index: -1;}
  }
  &__Content { height: 600px; position: relative; float: left; width: 100%;}
  &__Box { float: left; width: 100%; position: relative;  top: 50%; transform:translateY(-50%);}
  &__Heading { text-align: center; font-size: 80px; line-height: 112px; margin: 0; padding: 0; font-weight: 900; color: $white; position: relative;text-transform: uppercase}
  &__subHeading { text-align: center; font-size: 30px; line-height: 42px; margin: 0; padding: 0; font-weight: 300; color: $white; position: relative;}
}
.aboutPage {
  &__Inner {padding-top: 200px;float: left;padding: 30px;background: alpha($white,0.8);margin-top: 300px;margin-left: 30px;}
  &__Image {float: left;width: 100%;height: 800px; background-size: cover;background-repeat: no-repeat;background-position: center center;}
  float: left;width: 100%;
    h3 {font-size: 16px; margin: 0; color: $gray;text-transform: uppercase;font-weight: 700;}
    h2 {color: $brand;font-weight: 900; font-size: 50px;margin: 0;line-height: 1.2;}
    p {font-weight: 300; color: darken($gray, 15%);}
}
.aboutPageBottom {
  &__Inner {float: left;width: 100%;padding: 0 30px;margin-bottom: 100px;}
  &__Image {float: left;width: 100%;height: 700px; background-size: cover;background-repeat: no-repeat;background-position: center center;margin: 50px 0;}
  float: left;width: 100%;padding: 50px 0 80px 0;
    h3 {font-size: 16px; margin: 0; color: $gray;text-transform: uppercase;font-weight: 700;}
    h2 {color: $brand;font-weight: 900; font-size: 50px;margin: 0;line-height: 1.2;}
    p {font-weight: 300; color: darken($gray, 15%);}
    a {color: $brand;text-decoration: none;}
}
.addTopMargin {
    padding-top: 100px;
}