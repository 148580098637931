.siteHeader { color: $white;position: absolute;z-index: 100;background-color: alpha($white,0.8);float: left;width: 100%;padding: 0 20px;
  &__homeLogo {float: left;width: 100%;text-align: center;
    a {padding-left: 0 !important;text-decoration: none;line-height: 80px;
      img {line-height: 80px;vertical-align: middle;}
      &:hover {
        &:after {display: none !important;}
      }
    }
  }
}
.logo { margin: 0; font-size: 36px;
  a { margin: 0; 
    img {width: 200px;transition: 300ms $ease-in-out-quint;}
  }
}
.js-SiteHeader {
    &--is-Active {background: $white;opacity: 1;visibility: visible;width: 100%;margin-top: 80px !important;}
    &--is-Fixed {background: $white;position: fixed;left: 0px;width: 100%;-webkit-transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);top: -160px !important;}
}