.contactPage {float: left;width: 100%;padding: 0px 0 60px 0;
  &__Inner {float: left;width: 80%;margin-left: 10%;
    h4 {font-size: 40px; margin: 0; color: $brand;text-transform: uppercase;font-weight: 900;}
    h3 {font-size: 16px; margin: 0; color: $gray;text-transform: uppercase;font-weight: 700;}
    h2 {color: $brand;font-weight: 900; font-size: 50px;margin: 0;line-height: 1.2;}
    p {font-weight: 300; color: darken($gray, 15%);}
 &--contactForm {width: 100%;margin: auto;text-align: left;margin-top: 70px;float: left;
     
      
      input {height: 50px; width: 100%;border: 0;border: 1px solid $gray-light;color: $gray-light; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 16px;padding: 10px;}
      textarea {height: 250px; width: 100%;max-width: 100%;min-width: 100%;border: 0;border: 1px solid $gray-light;color: $gray-light;  background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 16px;padding: 10px;}
      input[type="submit"] {@include moreButton($gray-light, transparent); color: $gray-light;overflow: hidden;float: left;border: 1px solid $gray-light;width: auto;
      &:before {background: $gray-light;}
        &:hover {color: $white;background: $gray-light;}
      }
 }
  
    
    
  }
}
.noTop {
  padding-top: 0;margin-top: 0;
}
.map {
  float: left;width: 100%; height: 500px;
}