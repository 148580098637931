.siteFooter { background: $brand2; float: left;width: 100%;position: relative;z-index: 1;
    &__Widgets {float: left;width: 100%;background: $gray;padding: 50px 0;}
    &__Box {min-height: 300px;float: left;width: 25%;text-align: left; font-weight: 300;padding: 0 15px;
      h3 {font-size: 16px; color: $white; text-transform: uppercase;}
      p {font-size: 12px;color: $white;}
      ul {padding: 0;margin: 0;
        li { color: $white;list-style: none; font-size: 12px;
          a {text-decoration: none; color: $white; font-size: 12px;}
        }
      }
      &:nth-of-type(3) {padding-right: 50px;}
    }
    &__lastBox {min-height: 300px;float: left;width: 25%;text-align: center; font-weight: 300;background: $brand;position: relative;z-index: 1;
      &:before {position: absolute;content: '';z-index: -1;left: -20px;top: -50px;bottom: -130px;right: -20px;background: $brand;}
      h3 {font-size: 16px; color: $white; text-transform: uppercase;}
      p {font-size: 13px;color: $white;}
      a {font-size: 13px;color: $white;text-decoration: none;font-weight: bold;}
      ul {padding: 0;margin: 0;
        li { color: $white;list-style: none;height: 20px;display: inline-block;margin: 20px 5px 0 5px;
          a {text-decoration: none; color: $white;
            i {font-size: 30px;}
          }
        }
      }
    }
    .copy {height: 60px; background: $brand2; line-height: 60px; float: left;width: 100%;text-align: left;
     span {color: $brand; font-size: 12px;}
     a {text-decoration: none; color:$brand;}
     &__Developed {margin-left: 10px;
      a {color: $black;
        &:hover {color: $brand;}
      }
     }
    }
}