.nav { width: 100%; position: relative; font-size: 14px;float: left;
  &__Inner { @include outer-container; max-width: 960px; }
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: center; display: block;height: 80px;line-height: 80px;float: left;width: 100%;}
  li {display: inline-block; text-align: center; vertical-align: middle; position: relative;
      ul {transition: 300ms ease-in-out; position: absolute;float: left;width: 100%;text-align: center;visibility: hidden;opacity: 0;transition: visibility 0s, opacity 0.5s linear;height: auto;line-height:initial;background: $white;margin-top: -20px;
        li {display: block;
            a {line-height: 40px; font-size: 0.8em;}
        }
      }
      &:hover {
        ul {visibility: visible;opacity: 1;}
      }
  }
  a { color: $brand; text-transform: uppercase; font-size: 1em; text-decoration: none; line-height: 1; margin: 0; position: relative;transition: 300ms $ease-in-out-quint;padding-left: 5px;padding-right: 5px;font-weight: normal;
    &:hover {transition: 300ms $ease-in-out-quint;opacity: 0.8;
  
    }
  }
  &__Social {
    a { color: $social; font-size: 32px; display: inline-block; transition: 200ms;
      &:hover { color: $black; }
      &:after { display: none; }
    }
  }
  .current-menu-item {
    a {
      &:after { left: 30%; right: 30%; opacity: 1; }
    }
  }
  

  &--Footer { @extend .nav; margin-top: 40px;
    a { line-height: 120px;color: $white; }
  }
}
.langNav {
  float: right;position: absolute;top: -5px;right: 15px;
  ul {list-style: none;
    li {display: inline-block;
      a {text-decoration: none;color: $white;text-transform: capitalize; font-weight: 300;font-size: 14px;
        &:hover {color: $brand;}
      }
    }
  }
}