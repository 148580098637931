@media screen and (max-width: 960px) {  
.mobile {display: block;}
.nav {position: absolute;background: $brand;transform:translateX(-107%);z-index: 1000000000;transition:500ms !important;
  ul {height: 100vh;line-height: 1;display: block;padding-top: 20px;padding-left: 20px;}
  li {display: table;float: none;width: auto;line-height: 2;text-align: left;
    ul {position: relative;visibility: visible;opacity: 1;background-color: transparent;text-align: left;
      li {line-height: 1.4 !important;}
    }
  }
  a {float: none;width: auto;color: $white;}
}
.callNav {position: absolute;right: -55px;top: 20px;padding: 10px 15px;background: $brand;cursor: pointer;
  i {color: $white;transition:700ms;}
}
.activeNav {transform:translateX(-7%);opacity: 1;transition:500ms !important;
  .callNav {right: 10px;background: $white;
    i {color: $brand;transform:rotate(180deg);
      &:before {content: "\f00d" !important;}
    }
  }
}
.about {
  &__Left {width: 100%;height: auto;
    a {padding: 0;width: 100%;text-align: center;}
  }
  &__Right {width: 100%;}
}
.aboutBottom {
  &__Right{width: 100%;height: auto;
    a {padding: 0;width: 100%;text-align: center;}
  }
  &__Left {width: 100%;}
}
.homeBottom {
  height: auto;padding: 5% 0;
  &__Box {position: relative;float: left;width: 90%;height: auto;margin-left: 5%;margin-right: 5%;left: auto;top: auto;
    a {padding: 0;width: 100%;text-align: center;}
  }
}
.siteFooter{
  &__Box {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__lastBox {width: 100%;margin-top: 5%;padding: 5%;
    &:before {display: none;}
  }
  &__Widgets {padding-bottom: 0;}
  .copy {height: auto;line-height:initial;text-align: center;padding-left: 5%;padding-right: 5%;}
}
.aboutPage {
  &__Inner {margin-left: auto;margin-top: 100px;width: 100%;text-align: center;}
}
.aboutPage, .aboutPageBottom {width: 95%;margin-left: 2.5%;margin-right: 2.5%;margin-top: 80px;}
.newsPage {padding: 0;
 
 &__Box {
  &:last-of-type {margin-bottom: 0;}
   &:hover {transition: 300ms $ease-in-out-quint;
     &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($white,0.75); z-index: -1;transition: 300ms $ease-in-out-quint;}
    h4 {font-size: 20px; color: $gray-light;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;}
    h3 {color: $brand; font-size: 30px;margin: 0;transition: 300ms $ease-in-out-quint;}
    i {color: $brand;font-size: 40px;transform:rotate(0deg);}
    a {float: left;width: 100%;height: 300px;text-decoration: none;position: relative; padding: 0 50px;transition: 300ms $ease-in-out-quint;}
   }
 }
}
}