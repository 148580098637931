.newsPage {float: left;width: 100%;padding: 70px 0;position: relative;z-index: 1;
 
 &__Wrap {float: left; width: 100%; background: $white;}
 &__Box {float: left;width: 100%;height: 300px; background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative; z-index: 1;transition: 300ms $ease-in-out-quint;margin-bottom: 20px;
    &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($white,0.75); z-index: -1;transition: 300ms $ease-in-out-quint;}
    h4 {font-size: 20px; color: $gray-light;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;}
    h3 {color: $brand; font-size: 30px;margin: 0;transition: 300ms $ease-in-out-quint;}
    i {color: $brand;font-size: 40px;}
    a {float: left;width: 100%;height: 300px;text-decoration: none;position: relative; padding: 0 50px;transition: 300ms $ease-in-out-quint;}
   &--wrapText {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;text-align: left;}
   &--wrapRight {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;text-align: right;}
   &:hover {transition: 300ms $ease-in-out-quint;
    a {padding-left: 150px;padding-right: 150px;transition: 300ms $ease-in-out-quint;}
    h4 {color: $white;transition: 300ms $ease-in-out-quint;}
    h3 {color: $white;transition: 300ms $ease-in-out-quint;}
    i { color: $white;transform:rotate(180deg);}
    &:after {transition: 300ms $ease-in-out-quint; background: alpha($brand,0.75);}
   }
 }
}

.defineNewHeight {
  height: 650px;
}